import { formatBalance, BN, BN_TEN } from '@polkadot/util';

export const valueToBalance = (value: number, unit: string, basePower = formatBalance.getDefaults().decimals) => {
    const si = formatBalance.findSi(unit);

    const power = new BN(si.power + basePower);

    const [, decimal] = String(value).split('.');

    let result: BN;

    // todo https://github.com/polkadot-js/apps/blob/3b9dfee14b6ea99245e261fa6fc49b039119b18c/packages/react-components/src/InputNumber.tsx#L115
    if (decimal && Number(decimal) !== 0) {
        if (si.power - decimal.length < -basePower) {
            result = new BN(-1);
        }

        const div = new BN(String(value).replace(/\.\d*$/, ''));
        const modString = String(value).replace(/^\d+\./, '').substr(0, basePower);
        const mod = new BN(modString);

        result = div
            .mul(BN_TEN.pow(power))
            .add(mod.mul(BN_TEN.pow(new BN(basePower + si.power - modString.length))));
    } else {
        result = new BN(String(value).replace(/[^\d]/g, ''))
            .mul(BN_TEN.pow(power));
    }

    return result;
};
