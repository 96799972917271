import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import StatementContext from 'Models/statement';
import IF from './if';
import ELSE from './else';
import ELIF from './elif';

const Statement: React.FC & {
    IF: typeof IF;
    ELSE: typeof ELSE;
    ELIF: typeof ELIF;
} = (props) => {
    const { children } = props;

    const [state, setState] = useState<boolean>(false);

    const handleChange = useCallback((value: boolean) => {
        setState(value);
    }, [setState]);

    const context = useMemo(() => {
        return {
            state,
            change: handleChange,
        };
    }, [state, handleChange]);

    return (
        <StatementContext.Provider value={context}>
            {children}
        </StatementContext.Provider>
    );
};

Statement.IF = IF;
Statement.ELSE = ELSE;
Statement.ELIF = ELIF;

export default Statement;
