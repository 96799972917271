import * as React from 'react';
import { ComponentBase } from 'Components/global';
import ChainLoading from 'Components/loading/chain';
import classNames from 'classnames';
import Style from './index.less';

interface IProps extends ComponentBase {
    block?: boolean;
}

const Loading: React.FC<IProps> & {
    Chain: typeof ChainLoading;
} = (props) => {
    const { children, className, style, block } = props;

    const mergedClass = React.useMemo(() => {
        return classNames(Style.loading, {
            [Style.loadingBlock]: block,
        }, className);
    }, [className]);

    return (
        <div className={mergedClass} style={style}>
            <span className={Style.loadingIcon}>
                <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="spinner-third"
                    className="svg-inline--fa fa-spinner-third fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                >
                    <path
                        fill="currentColor"
                        d="M283.2 65.92C267.6 63.69 256 50.32 256 34.52c0-19.38 17.19-34.69 36.38-31.95C416.4 20.29 512 127.2 512 256c0 33.38-6.544 65.26-18.27 94.49c-7.162 17.86-28.85 24.87-45.56 15.32c-13.74-7.854-19.62-24.53-13.75-39.23C443.2 304.7 448 280.9 448 256C448 159.4 376.3 79.18 283.2 65.92z"
                    />
                </svg>
            </span>
            {children}
        </div>
    );
};

Loading.Chain = ChainLoading;

export default Loading;
