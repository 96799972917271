import * as React from 'react';
import { useLocation } from 'react-router';
import Navigation from 'Components/navigation';
import NavigationContext from 'Models/navigation';

const NavigationProvider: React.FC = (props) => {
    const { children } = props;
    const location = useLocation();
    const { pathname } = location;

    const current = React.useMemo(() => {
        if (pathname === '/' || pathname.startsWith('/dashboard')) {
            return 'dashboard';
        }

        if (pathname.startsWith('/transactions')) {
            return 'transaction';
        }

        if (pathname.startsWith('/domains/market')) {
            return 'domains/market';
        }

        if (pathname.startsWith('/register') || pathname.startsWith('/domains')) {
            return 'domains';
        }
        return '';
    }, [pathname]);

    return (
        <NavigationContext.Provider value={{}}>
            <Navigation current={current} />
            {children}
        </NavigationContext.Provider>
    );
};

export default NavigationProvider;
