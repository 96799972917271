import * as React from 'react';
import { ComponentBase } from 'Components/global';
import classNames from 'classnames';
import ListContext from 'Components/dropdown/list-context';
import DropdownContext from 'Components/dropdown/context';
import Style from './index.less';

interface IProps extends ComponentBase {
    onChange?: (id: string) => void;
    block?: boolean;
    value?: string;
}

const DropdownOverlay: React.FC<IProps> = (props) => {
    const { children, className, style, onChange, block, value } = props;

    const { change, trigger } = React.useContext(DropdownContext);

    const mergedClass = React.useMemo(() => {
        return classNames(Style.dropdownOverlay, {
            [Style.dropdownOverlayBlocked]: block,
        }, className);
    }, [className, block]);

    const handleListChange = React.useCallback((key: string) => {
        onChange && onChange(key);
        change && change(false);
    }, [onChange, change]);

    const context = React.useMemo(() => {
        return {
            value,
            change: handleListChange,
        };
    }, [value, handleListChange]);

    const handleMouseLeave = React.useCallback(() => {
        if (trigger === 'click') {
            return;
        }
        change && change(false);
    }, [change, trigger]);

    const handleMouseEnter = React.useCallback(() => {
        if (trigger === 'click') {
            return;
        }
        change && change(true);
    }, [change, trigger]);

    return (
        <>
            <div className={Style.dropdownOverlayMargin} onMouseEnter={handleMouseEnter} />
            <div className={mergedClass} style={style} onMouseLeave={handleMouseLeave}>
                <ListContext.Provider value={context}>
                    {children}
                </ListContext.Provider>
            </div>
        </>
    );
};

export default DropdownOverlay;
