import * as React from 'react';
import classNames from 'classnames';
import { useMounted } from 'Hooks/use-mounted';
import { ComponentBase } from 'Components/global';
import ButtonGroup from 'Components/button/group';
import Loading from 'Components/loading';
import { Link } from 'react-router-dom';
import Style from './index.less';
import useIntl from 'Hooks/use-intl';

export interface IProps extends ComponentBase, Omit<React.ButtonHTMLAttributes<any>, 'type' | 'onClick'> {
    component?: any;
    loading?: boolean;
    disabled?: boolean;
    type?: 'primary' | 'default';
    round?: boolean;
    outline?: boolean;
    size?: 'normal' | 'large' | 'small';
    block?: boolean;
    onClick?: (e: React.MouseEvent) => (void | Promise<any>);
    href?: string;
    target?: React.HTMLAttributeAnchorTarget;
    rel?: string;
}

const Button: React.FC<IProps> & {
    Group: typeof ButtonGroup;
} = (props) => {
    const {
        component,
        loading,
        disabled,
        type = 'default',
        size = 'normal',
        block,
        round,
        outline,
        children,
        className,
        style,
        onClick,
        href,
        ...extraProps
    } = props;

    const [innerLoading, setLoading] = React.useState(false);

    const {hasMessage, formatMessage} = useIntl();

    const mounted = useMounted();

    const mergedLoading = loading || innerLoading;

    const mergedClass = React.useMemo(() => {
        return classNames(Style.button, `${Style.button}-${type}`, `${Style.button}-${size}`, {
            [Style.buttonLoading]: mergedLoading,
            [Style.buttonOutline]: outline,
            [Style.buttonRound]: round,
            [Style.buttonBlock]: block,
        }, className);
    }, [mergedLoading, className, type, size, block, round, outline]);

    const handleClick = React.useCallback(async (e: React.MouseEvent) => {
        if (!onClick) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        const res = onClick(e);

        if (res && res.then) {
            if (!mounted.current) {
                return;
            }
            setLoading(true);

            try {
                await res;
            } finally {
                if (!mounted.current) {
                    return;
                }
                setLoading(false);
            }
        }
    }, [onClick, mounted, setLoading]);

    const Component = React.useMemo(() => {
        if (component) {
            return component;
        }

        if (href) {
            return Link;
        }

        return 'button';
    }, [component, href]);

    const mergedChildren = React.useMemo(() => {
        if(typeof  children !== 'string') {
            return children;
        }

        return hasMessage(children) ? formatMessage(children) : children;
    }, [children, hasMessage, formatMessage]);

    return React.createElement(
        Component,
        {
            className: mergedClass,
            disabled,
            style,
            ...extraProps,
            onClick: handleClick,
            ...(component === 'a' ? {
                href,
            } : {
                to: href,
            }),
        } as any,
        (
            <>
                {
                    mergedLoading ? (
                        <Loading />
                    ) : null
                }
                {mergedChildren}
            </>
        ),
    );
};

Button.Group = ButtonGroup;

export default Button;
