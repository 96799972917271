import * as React from 'react';

export const useMounted = (deps: React.DependencyList = []) => {
    const mounted = React.useRef(false);

    React.useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, deps);

    return mounted;
};
