import * as React from 'react';
import { ReactNode, useContext, useEffect, useMemo } from 'react';
import StatementContext from 'Models/statement';

interface IProps {
    condition: boolean | (() => boolean);
    children: ReactNode;
}

const ELIF = (props: IProps) => {
    const { condition, children } = props;

    const context = useContext(StatementContext);

    const result = useMemo(() => {
        if (context.state) {
            return false;
        }
        return typeof condition === 'function' ? condition() : condition;
    }, [context.state, condition]);

    useEffect(() => {
        if (context.state) {
            return;
        }
        context.change && context.change(result);
    }, [result, context]);

    if (result) {
        return children;
    }
    return null;
};

export default ELIF;
