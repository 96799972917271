import * as React from 'react';

interface IListContext {
    value?: string;
    change?: (key: string) => void
}

const ListContext = React.createContext<IListContext>({});

export default ListContext;
