import * as React from 'react';
import Style from './index.less';
import { ComponentBase } from 'Components/global';
import classNames from 'classnames';
import useIntl from 'Hooks/use-intl';

interface IProps extends ComponentBase {
    width?: React.CSSProperties['width'];
    height?: React.CSSProperties['height'];
    title?: string;
}

const Section: React.FC<IProps> = (props) => {
    const { children, className, style, width, height, title } = props;

    const { hasMessage, formatMessage } = useIntl();

    const innerClassName = React.useMemo(() => {
        return classNames(Style.section, className);
    }, [className]);

    const mergedStyle = React.useMemo(() => {
        return {
            ...style,
            width: width || style?.width,
            height: height || style?.height,
        };
    }, [style, width, height]);

    const mergedTitle = React.useMemo(() => {
        return (typeof title === 'string' && hasMessage(title)) ? formatMessage(title) : title;
    }, [title, hasMessage, formatMessage]);

    return (
        <section className={innerClassName} style={mergedStyle}>
            {
                title ? <h2 className={Style.sectionTitle}>{mergedTitle}</h2> : null
            }
            {children}
        </section>
    );
};

export default Section;
