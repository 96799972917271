import { IntlShape as ReactIntlShape, MessageDescriptor, useIntl as useReactIntl } from 'react-intl';
import { Options as IntlMessageFormatOptions } from 'intl-messageformat/src/core';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import usePrefix from 'Hooks/./use-prefix';
import { useCallback } from 'react';

interface IntlShape extends Omit<ReactIntlShape, 'formatMessage'> {
    formatMessage: (
        message?: string | MessageDescriptor,
        values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
        options?: IntlMessageFormatOptions,
    ) => string;
    hasMessage: (message?: string | MessageDescriptor) => boolean;
}

const useIntl = (prefix?: string): IntlShape => {
    const intl = useReactIntl();

    const mergedPrefix = usePrefix(prefix);

    const {
        messages,
        formatMessage: originFormatMessage,
        ...extra
    } = intl;

    const getDescriptor = useCallback((key: MessageDescriptor | string): MessageDescriptor => {
        if (typeof key === 'string') {
            const mergedKey = mergedPrefix ? `${mergedPrefix}.${key}` : key;

            if (!messages[mergedKey] && messages[key]) {
                return {
                    id: key,
                    defaultMessage: messages[key],
                };
            }

            return {
                id: mergedKey,
                defaultMessage: messages[mergedKey],
            };
        }
        return key;
    }, [mergedPrefix, messages]);

    const formatMessage = useCallback((
        message?: string | MessageDescriptor,
        values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
        options?: IntlMessageFormatOptions,
    ) => {
        if (!message) {
            return '';
        }

        const descriptor = getDescriptor(message);

        if (!descriptor) {
            return '';
        }

        return originFormatMessage(descriptor, values, options);
    }, [originFormatMessage, getDescriptor]);

    const hasMessage = useCallback((
        message?: string | MessageDescriptor,
    ) => {
        if (!message) {
            return false;
        }

        const descriptor = getDescriptor(message);

        return !!(descriptor && descriptor.defaultMessage);
    }, [getDescriptor]);

    return {
        ...extra,
        messages,
        formatMessage,
        hasMessage,
    };
};

export default useIntl;
