import * as React from 'react';
import { IntlProvider } from 'react-intl';
import LocalContext, { ILocalContext } from 'Models/local';
import Locals from 'I18N/locale';
import { Locale as AntLocale } from 'antd/es/locale-provider';
import { LocaleSpecification as MomentLocale } from 'moment';
import * as DEFAULT_LOCALE from 'I18N/locale/en-us';
import { defineLocale as defineMomentLocale, locale as momentLocale } from 'moment';
import { ConfigProvider } from 'antd';
import localeCache from 'Utils/locale';

export interface ILocalConfig {
    locale: LANG;
    Messages: InputMessages;
    Ant: AntLocale;
    AntV: 'zh-CN' | 'en-US';
    Moment: string;
    MomentDefine?: MomentLocale;
}

export type LANG = 'en-us' | 'zh-cn';

export interface InputMessages {
    [key: string]: InputMessages | string;
}

export const AVAILABLE_LANGUAGE: LANG[] = ['en-us', 'zh-cn'];

export const defaultLang: LANG = (() => {
    let lang: any = window.localStorage.getItem('lang');

    if (!lang) {
        lang = window.navigator.language;
    }

    lang = lang ? lang.toLowerCase() : '';

    if (!lang || !AVAILABLE_LANGUAGE.includes(lang)) {
        lang = AVAILABLE_LANGUAGE[0];
    }

    return lang;
})();

const mergeMessages = (
    messages: InputMessages,
    prefix: string = '',
    total: Record<string, string> = {},
) => {
    if(typeof messages !== 'object') {
        total[prefix] = messages;

        return total;
    }
    Object.keys(messages).forEach((key) => {
        const value = messages[key];

        key = prefix ? `${prefix}.${key}` : key;

        if (total[key]) {
            console.warn(`[LocalProvider] duplicate message key ${key}`);
        }

        if (Array.isArray(value)) {
            value.forEach((item, index) => {
                mergeMessages(item, `${key}[${index}]`, total);
            });
            return;
        }
        if (typeof value === 'object') {
            mergeMessages(value, key, total);
            return;
        }
        total[key] = value as string;
    });
    return total;
};

export interface ILocalItem extends Omit<ILocalConfig, 'Messages'> {
    Messages: Record<string, string>;
}

export const LOCALE_CACHE: Partial<Record<LANG, ILocalItem>> = {
    'en-us': {
        ...DEFAULT_LOCALE,
        Messages: mergeMessages(DEFAULT_LOCALE.Messages),
    },
};

const getMessageByLang = async (lang: LANG): Promise<ILocalItem> => {
    const cache = LOCALE_CACHE[lang];
    if (cache) {
        return cache;
    }
    const loadLocale = Locals[lang];

    const locale = loadLocale ? await loadLocale() : undefined;

    if (locale) {
        if (locale.Moment && locale.MomentDefine) {
            defineMomentLocale(locale.Moment, locale.MomentDefine);
        }

        LOCALE_CACHE[lang] = {
            ...locale,
            Messages: mergeMessages(locale.Messages),
        };

        return LOCALE_CACHE[lang]!;
    }

    return LOCALE_CACHE['en-us']!;
};

interface IProps {
}

const LocalProvider: React.FC<IProps> = (props) => {
    const { children } = props;

    const [innerLang, setInnerLang] = React.useState(defaultLang);

    const [locale, setLocale] = React.useState<ILocalItem>(LOCALE_CACHE['en-us']!);

    const setLang = React.useCallback((lang: LANG) => {
        if (lang.toLowerCase() === innerLang) {
            return;
        }
        setInnerLang(lang);
        window.localStorage.setItem('lang', lang.toLowerCase());
    }, []);

    const value: ILocalContext = React.useMemo(() => {
        return {
            lang: innerLang!,
            setLang,
            locale,
        };
    }, [locale, innerLang, setLang]);

    React.useEffect(() => {
        getMessageByLang(innerLang).then((locale) => {
            localeCache.locale = locale;
            setLocale(locale);
            momentLocale(locale.Moment);
        });
    }, [innerLang, setLocale]);

    const title = locale.Messages['base.title'];

    React.useEffect(() => {
        window.document.title = title;
    }, [title]);

    return (
        <LocalContext.Provider value={value}>
            <ConfigProvider locale={locale.Ant}>
                <IntlProvider
                    locale={innerLang}
                    messages={locale.Messages}
                >
                    {children}
                </IntlProvider>
            </ConfigProvider>
        </LocalContext.Provider>
    );

};

export default LocalProvider;
