import * as React from 'react';
import { PropsWithChildren, useContext } from 'react';
import StatementContext from 'Models/statement';

const ELSE = (props: PropsWithChildren<unknown>) => {
    const { children } = props;

    const context = useContext(StatementContext);

    if (context.state) {
        return null;
    }

    return (
        <>
            {children}
        </>
    );
};

export default ELSE;
