import * as React from 'react';
import Modal from 'Components/modal';
import WalletContext from 'Models/wallet';
import Button from 'Components/button';
import Dropdown from 'Components/dropdown';
import Empty from 'Components/empty';
import AccountSelectorItem from 'Components/account-selector/item';
import PrefixProvider from 'Providers/prefix-provider';
import FormattedMessage from 'Components/intl/message';
import { ComponentBase } from 'Components/global';
import classNames from 'classnames';
import Style from './index.less';

interface IProps extends ComponentBase {
    visible?: boolean;
    onChange?: (account: string) => void;
}

const AccountSelector = (props: IProps) => {
    const { visible, onChange, className, style } = props;

    const { accounts, setAccount, account } = React.useContext(WalletContext);
    const [innerAccount, setInnerAccount] = React.useState(account || accounts[0].address);

    const accountInfo = React.useMemo(() => {
        return accounts.find(item => item.address === innerAccount) || accounts[0];
    }, [innerAccount, accounts]);

    const handleChange = (key: string) => {
        setInnerAccount(key);
    };

    const handleConfirm = () => {
        setAccount(innerAccount!);
        onChange && onChange(innerAccount!);
    };

    const mergedClass = React.useMemo(() => {
        return classNames(Style.accountSelectorWrapper, className);
    }, [className]);

    return (
        <PrefixProvider value="component.account.selector">
            <Modal visible={visible} mask={false} position="top-right" className={mergedClass} style={style}>
                <FormattedMessage component="h2" id="title" className={Style.accountSelectorTitle} />
                <Dropdown className={Style.accountSelector}>
                    <Dropdown.Trigger className={Style.accountSelectorItem}>
                        {
                            accountInfo ? (
                                <AccountSelectorItem
                                    name={accountInfo.meta.name!}
                                    address={accountInfo.address}
                                />
                            ) : (
                                <FormattedMessage id="empty" />
                            )
                        }
                    </Dropdown.Trigger>
                    <Dropdown.Overlay onChange={handleChange} block>
                        {
                            accounts.length ? accounts.map((item) => (
                                <Dropdown.Item
                                    value={item.address}
                                    key={item.address}
                                    className={Style.accountSelectorItem}
                                >
                                    <AccountSelectorItem
                                        name={item.meta.name!}
                                        address={item.address}
                                    />
                                </Dropdown.Item>
                            )) : (
                                <Empty />
                            )
                        }
                    </Dropdown.Overlay>
                </Dropdown>
                <Button type="primary" block style={{ fontSize: 14, fontWeight: 400 }} onClick={handleConfirm}>
                    <FormattedMessage id="confirm" prefix="common.action" />
                </Button>
            </Modal>
        </PrefixProvider>
    );
};

export default AccountSelector;
