import { Balance } from '@polkadot/types/interfaces';
import { formatBalance } from '@polkadot/util';
import { bnToBn } from '@polkadot/util/bn/toBn';

const DEFAULT = formatBalance.getDefaults();

export class Free {
    public value?: Balance | number;

    constructor(value?: Balance | number) {
        if (value && value.toString && value.toString() === '0') {
            this.value = 0;
        } else {
            this.value = value;
        }
    }

    get number() {
        if (!this.value) {
            return 0;
        }
        return Number(formatBalance(this.value, {
            withSi: false,
            withUnit: false,
        }));
    }

    toString() {
        if (!this.value) {
            return `0 ${DEFAULT.unit}`;
        }
        return formatBalance(this.value);
    }

    get unit() {
        if (!this.value) {
            return DEFAULT.unit;
        }

        const si = this.si;

        if (si.value === '-') {
            return 'Unit';
        }

        return `${si.value}Unit`;
    }

    get si() {
        return formatBalance.calcSi(bnToBn(this.value).toString());
    }

    get unitType() {
        return this.si.value;
    }
}
