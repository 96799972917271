import * as React from 'react';
import classNames from 'classnames';
import Style from './index.less';

interface IProps {
    className?: string;
    style?: React.CSSProperties;
    center?: boolean;
    width?: React.CSSProperties['width'];
    height?: React.CSSProperties['height'];
    minHeight?: React.CSSProperties['minHeight'];
    fluid?: boolean;
}

const Container: React.FC<IProps> = (props) => {
    const { children, center, className, fluid, style, width, height, minHeight } = props;

    const innerClassName = React.useMemo(() => {
        return classNames(Style.container, {
            [Style.centered]: center,
            [Style.fluid]: fluid,
        }, className);
    }, [className, center, fluid]);

    const mergedStyle = React.useMemo(() => {
        return {
            ...style,
            width: width || style?.width,
            height: height || style?.height,
            minHeight: minHeight || style?.minHeight,
        };
    }, [style, width, height]);

    return (
        <main className={innerClassName} style={mergedStyle}>
            {children}
        </main>
    );
};

export default Container;
