import * as React from 'react';
import Style from './index.less';

interface IProps {
    onClick?: () => void
}

const Dimmer: React.FC<IProps> = (props) => {
    const { onClick, children } = props;

    return (
        <>
            <div className={Style.dimmer} onClick={onClick}/>
            {children}
        </>
    );
};

export default Dimmer;
