import * as React from 'react';
import * as ExtensionTypes from '@polkadot/extension-inject/types';

export interface IWalletContext {
    extension?: ExtensionTypes.InjectedExtension;
    accounts: ExtensionTypes.InjectedAccountWithMeta[];
    account?: string;
    setAccount: (address: string) => void;
}

const WalletContext = React.createContext<IWalletContext>({
    accounts: [],
    setAccount: () => {
    },
});

export default WalletContext;
