import * as React from 'react';
import { defaultLang, ILocalItem, LANG } from 'Providers/local-provider/index';

export interface ILocalContext {
    lang: LANG;
    locale?: ILocalItem;
    setLang: (lang: LANG) => void
}

const LocalContext = React.createContext<ILocalContext>({
    lang: defaultLang,
    setLang: () => {
    },
});

export default LocalContext;
