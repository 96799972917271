import * as React from 'react';
import { Identicon } from '@polkadot/react-identicon';
import Style from './index.less';

interface IProps {
    name: string;
    address: string;
}

const AccountSelectorItem = (props: IProps) => {
    const { name, address } = props;

    return (
        <>
            <Identicon
                size={44}
                className={Style.accountSelectorItemAvatar}
                value={address}
                theme="polkadot"

            />
            <div className={Style.accountSelectorItemInfo}>
                <p className={Style.accountSelectorItemInfoTitle}>{name}</p>
                <p>{address}</p>
            </div>
        </>
    );
};

export default AccountSelectorItem;
