import * as React from 'react';
import { ComponentBase } from 'Components/global';
import Style from './index.less';
import classNames from 'classnames';
import { random } from 'lodash';

interface IProps extends ComponentBase {
    loading?: boolean;
}

const COLORS = ['#39B672', '#FC3A92', '#D32273', '#F55DE6', '#1770D9', '#FFC961'];

const ROWS = new Array(4).fill(undefined).map((_, i) => i);

const TIMEOUT = 1000;

const ChainLoading: React.FC<IProps> = (props) => {
    const { loading = true, children, className, style } = props;

    const container = React.useRef<HTMLDivElement>(null);
    const timeout = React.useRef<number>();

    const [cols, setCols] = React.useState(0);
    const [colors, setColors] = React.useState<Array<Array<string | undefined>>>([]);

    const mergedClass = React.useMemo(() => {
        return classNames(Style.loadingChain, className);
    }, [className]);

    const handleResize = React.useCallback(() => {
        if (!container.current) {
            return;
        }

        const width = container.current.offsetWidth;

        const cols = Math.floor((width - 10) / 30) + 1;

        setCols(cols);
    }, [setCols]);

    React.useEffect(() => {
        if (!container.current) {
            return;
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [container.current, handleResize]);

    const handleSetColors = React.useCallback(() => {
        setColors((colors) => {
            if (colors[0] && (colors[0].length > cols)) {
                return colors.map(item => item.slice.call(0, cols));
            }
            if (colors[0] && (colors[0].length < cols)) {
                return new Array(4).fill(0).map(() => {
                    return new Array(cols).fill(undefined);
                });
            }

            const total = cols * 4;

            const colorsCount = total * 0.3;
            const lineColorsCount = Number(Number(colorsCount / 3).toFixed(0));

            return new Array(4).fill(0).map(() => {
                let lineCount = 0;
                return new Array(cols).fill(undefined).map(() => {
                    if (lineCount >= lineColorsCount) {
                        return undefined;
                    }
                    if (random(0, 1) === 0) {
                        return undefined;
                    }
                    lineCount++;
                    return COLORS[random(0, COLORS.length)];
                });
            });
        });
    }, [cols, setColors]);

    React.useEffect(() => {
        if (timeout.current) {
            timeout.current && window.clearInterval(timeout.current);
        }
        timeout.current = window.setInterval(() => {
            handleSetColors();
        }, TIMEOUT);
        handleSetColors();
        return () => {
            timeout.current && window.clearInterval(timeout.current) && (timeout.current = undefined);
        };
    }, [handleSetColors]);

    const COLS = new Array(cols).fill(undefined);

    return (
        <div ref={container} className={mergedClass} style={style}>
            {
                loading ? ROWS.map((index) => {
                    const COLORS = colors[index] || [];
                    return (
                        <div key={index} className={Style.loadingChainRow}>
                            {
                                COLS.map((_, index) => {
                                    const color = COLORS[index];
                                    return color ? (
                                        <span key={index} style={{ backgroundColor: color }} />
                                    ) : <span key={index} />;
                                })
                            }
                        </div>
                    );
                }) : children
            }
        </div>
    );
};

export default ChainLoading;
