import { MessageDescriptor } from 'react-intl';
import { PrimitiveType } from 'intl-messageformat';
import * as React from 'react';
import useIntl from 'Hooks/use-intl';
import { ComponentBase } from 'Components/global';

type IProps = {
    id: string | MessageDescriptor
} | {
    children: string | MessageDescriptor;
}

interface IPropsBase extends ComponentBase {
    prefix?: string;
    values?: Record<string, PrimitiveType>;
}

interface IPropsWithReactComponent<T> {
    component?: React.ComponentType<T>;
}

interface IPropsWithHtmlComponent<T> {
    component?: T;
}

function FormattedMessage(props: IProps & IPropsBase): React.ReactElement
function FormattedMessage<T extends keyof JSX.IntrinsicElements>(props: IProps & IPropsBase & IPropsWithHtmlComponent<T> & JSX.IntrinsicElements[T]): React.ReactElement
function FormattedMessage<T extends {} = any>(props: IProps & IPropsBase & IPropsWithReactComponent<T> & T): React.ReactElement
function FormattedMessage<T>(props: IProps &
    (T extends keyof JSX.IntrinsicElements ?
        (IPropsWithHtmlComponent<T> & JSX.IntrinsicElements[T]) :
        (IPropsWithReactComponent<T> & T)),
): React.ReactElement {
    const { values, component, prefix, id, children, ...extraProps } = props as any;

    const key = 'id' in props ? id : children;

    const { formatMessage } = useIntl(prefix);

    const message = React.useMemo(() => {
        return formatMessage(key, values);
    }, [values, formatMessage, key]);

    if (!component) {
        return <>{message}</>;
    }

    return React.createElement(component, {
        ...extraProps,
        dangerouslySetInnerHTML: {
            __html: message
        }
    });
}

export default FormattedMessage;
