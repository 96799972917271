import * as React from 'react';

export interface IDropdownContext {
    visible: boolean;
    change: (visible: boolean) => void;
    arrow: boolean;
    disabled: boolean;
    trigger?: 'hover' | 'click';
}

const DropdownContext = React.createContext<IDropdownContext>({
    visible: false,
    change: () => {
    },
    arrow: true,
    disabled: false,
});

export default DropdownContext;
