import * as React from 'react';
import PrefixContext from 'Models/prefix';
import usePrefix from 'Hooks/use-prefix';

interface IProps {
    value?: string;
    extends?: boolean;
}

const SUPER_REGEX = /^super((\.)|(.?$))/;

const PrefixProvider: React.FC<IProps> = (props) => {
    const { value, children, extends: shouldExtends } = props;

    const superd = usePrefix();

    const mergedValue = React.useMemo(() => {
        const superPrefix = superd ? `${superd}.` : '';
        if (shouldExtends) {
            return value ? value.replace(/(super\.)?/, superPrefix) : superd;
        }

        if (value && SUPER_REGEX.exec(value)) {
            return value.replace(SUPER_REGEX, superPrefix).replace(/\.$/, '');
        }

        return value;
    }, [value, shouldExtends, superd]);

    return (
        <PrefixContext.Provider value={mergedValue}>
            {children}
        </PrefixContext.Provider>
    );
};

export default PrefixProvider;
