import * as React from 'react';
import { ComponentBase } from 'Components/global';
import classNames from 'classnames';
import ListContext from 'Components/dropdown/list-context';
import Style from './index.less';

interface IProps extends ComponentBase {
    value?: string;
    onClick?: (value: string) => void;
}

const DropdownItem: React.FC<IProps> = (props) => {
    const { children, className, style, value, onClick } = props;

    const context = React.useContext(ListContext);

    const mergedClass = React.useMemo(() => {
        return classNames(Style.dropdownItem, className);
    }, [className]);

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();

        context.change && context.change(value!);
        onClick && onClick(value!);
    };

    const preventBlur = (e: React.MouseEvent) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
    };

    return (
        <div className={mergedClass} style={style} onMouseDown={preventBlur} onClick={handleClick}>
            {children}
        </div>
    );
};

export default DropdownItem;
