import * as React from 'react';
import classNames from 'classnames';
import Style from './index.less';
import { ComponentBase } from 'Components/global';
import DropdownContext from 'Components/dropdown/context';

interface IProps extends ComponentBase {
    border?: boolean;
}

const DropdownTrigger: React.FC<IProps> = (props) => {
    const { children, className, style, border = true } = props;

    const context = React.useContext(DropdownContext);

    const mergedClass = React.useMemo(() => {
        return classNames(Style.dropdownTrigger, className);
    }, [className, border]);

    const handleClick = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();

        if (!context.change || context.trigger === 'hover') {
            return;
        }

        context.change && context.change(!context.visible);
    }, [context.visible, context.change, context.trigger]);

    const handleMouseEnter = React.useCallback((e: React.MouseEvent) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();

        if (!context.change || context.trigger === 'click') {
            return;
        }

        if (!context.visible) {
            context.change(true);
        }
    }, [context.visible, context.change, context.trigger]);

    return (
        <div
            className={mergedClass}
            style={style}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
        >
            {children}
        </div>
    );
};

export default DropdownTrigger;
