import { AccountData } from '@polkadot/types/interfaces';
import { Free } from 'Utils/entities/free';

export default class BalanceChangeEvent extends Event {
    public value: AccountData;

    constructor(value: AccountData, eventInitDict?: EventInit) {
        super('balance-change', eventInitDict);

        this.value = value;
    }

    get free() {
        return new Free(this.value.free);
    }

    get reserved() {
        return new Free(this.value.reserved);
    }
}
