import * as React from 'react';
import {
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import { lazy } from 'Components/lazy';
import Statement from 'Components/statements/statement';

const AppRouter = () => (
    <Switch>
        <Redirect exact path="/" to="/register" />
        <Route exact path="/register" component={lazy(() => import('Pages/domains/search'))} />
        <Route exact path="/domains" component={lazy(() => import('Pages/domains/list'))} />
        <Route exact path="/domains/market" component={lazy(() => import('Pages/domains/market'))} />
        <Route exact path="/domains/market/:key" component={lazy(() => import('Pages/domains/market'))} />
        <Route exact path="/domains/:domain/board" component={lazy(() => import('Pages/domains/board'))} />
        <Route exact path="/domains/:domain" component={lazy(() => import('Pages/domains/detail'))} />
        <Route exact path="/domains/:domain/sell" component={lazy(() => import('Pages/domains/sell'))} />
        <Route exact path="/dashboard" component={lazy(() => import('Pages/dashboard'))} />
        <Route exact path="/transactions" component={lazy(() => import('Pages/transactions/list'))} />
        <Route exact path="/transactions/send" component={lazy(() => import('Pages/transactions/send'))} />
        <Route exact path="/transactions/send/:domain" component={lazy(() => import('Pages/transactions/confirm'))} />
        <Statement>
            <Statement.IF condition={true}>
                <Route exact path="/migration" component={lazy(() => import('Pages/migration'))} />
            </Statement.IF>
        </Statement>
        <Route component={lazy(() => import('Pages/error/404'))} />
    </Switch>
);

export default AppRouter;
