import * as React from 'react';
import { createPortal } from 'react-dom';
import Dimmer from 'Components/dimmer';
import { ComponentBase } from 'Components/global';
import classNames from 'classnames';
import Style from './index.less';

interface IProps extends ComponentBase {
    mask?: boolean;
    visible?: boolean;
    position?: 'center' | 'top-right';
    onClose?: () => void;
    afterClose?: () => void;
    container?: HTMLElement;
}

const Modal: React.FC<IProps> = function (props) {
    const {
        mask = true,
        visible = false,
        container,
        children,
        className,
        position = 'center',
        style,
        onClose,
        afterClose,
    } = props;

    const [closing, setClosing] = React.useState(false);

    const containerRef = React.useRef<HTMLDivElement>(container as any);

    if (!containerRef.current) {
        const div = window.document.createElement('div');
        window.document.body.appendChild(div);
        containerRef.current = div;
    }

    React.useEffect(() => {
        if (container) {
            return;
        }
        window.document.body.appendChild(containerRef.current);

        return () => {
            window.document.body.removeChild(containerRef.current);
        };
    }, [container, containerRef]);

    const mergedClass = React.useMemo(() => {
        return classNames(Style.modal, `${Style.modal}-${position}`, {
            [Style.modalClosing]: closing,
        }, className);
    }, [closing, className, position]);

    const mountPoint = container || containerRef.current;

    const handleClosing = () => {
        if (!onClose && !afterClose) {
            return;
        }
        onClose && onClose();

        setClosing(true);
        setTimeout(() => {
            afterClose && afterClose();
            setClosing(false);
        }, 300);
    };

    if (!mountPoint) {
        return null;
    }

    if (!container && containerRef.current) {
        const childName = ((Array.isArray(children) ? 0 : children) as any)?._owner?.type?.name;

        childName && containerRef.current.setAttribute('is', childName);
    }

    return visible ? createPortal((
        <>
            {mask ? <Dimmer onClick={handleClosing} /> : null}
            <div className={mergedClass} style={style}>
                {children}
            </div>
        </>
    ), mountPoint) : null;
};

export default Modal;
