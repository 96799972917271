import * as React from 'react';
import Container from './container';
import Group from 'Components/layout/group';
import Section from 'Components/layout/section';
import Style from './index.less';

const Layout: React.FC & {
    Container: typeof Container;
    Group: typeof Group;
    Section: typeof Section;
} = ({ children }) => {
    return (
        <div className={Style.pageContainer}>
            {children}
        </div>
    );
};

Layout.Container = Container;
Layout.Group = Group;
Layout.Section = Section;

export default Layout;
