import * as React from 'react';
import { ComponentBase } from 'Components/global';
import DropdownContext from 'Components/dropdown/context';
import DropdownTrigger from 'Components/dropdown/trigger';
import DropdownOverlay from 'Components/dropdown/overlay';
import classNames from 'classnames';
import DropdownItem from 'Components/dropdown/item';
import Style from './index.less';

export type IDropdownTrigger = 'click' | 'hover';

interface IProps extends ComponentBase {
    visible?: boolean;
    disabled?: boolean;
    arrow?: boolean;
    onChange?: (visible: boolean) => void;
    bordered?: boolean;
    trigger?: 'hover' | 'click';
    block?: boolean;
}

const Dropdown: React.FC<IProps> & {
    Trigger: typeof DropdownTrigger;
    Overlay: typeof DropdownOverlay;
    Item: typeof DropdownItem;
} = (props) => {
    const {
        children,
        className,
        style,
        visible,
        onChange,
        arrow: inputArrow,
        disabled = false,
        bordered = true,
        trigger,
        block = false,
    } = props;

    const arrow = disabled && !('arrow' in props) ? false : (
        !('arrow' in props) ? true : inputArrow!
    );

    const [innerVisible, setInnerVisible] = React.useState(visible || false);

    const handleVisibleChange = React.useCallback((visible: boolean) => {
        setInnerVisible(visible);
        onChange && onChange(visible);
    }, [setInnerVisible, onChange]);

    const mergedVisible = React.useMemo(() => {
        if (visible !== undefined) {
            return visible;
        }

        return innerVisible;
    }, [visible, innerVisible]);

    const contextValue = React.useMemo(() => {
        return {
            visible: mergedVisible,
            change: handleVisibleChange,
            arrow,
            disabled,
            trigger,
        };
    }, [mergedVisible, handleVisibleChange, arrow, disabled]);

    const mergedClass = React.useMemo(() => {
        return classNames(Style.dropdown, className, {
            [Style.dropdownOpened]: mergedVisible,
            [Style.dropdownDisabled]: disabled,
            [Style.dropdownWithArrow]: arrow,
            [Style.dropdownBordered]: bordered,
            [Style.dropdownBlocked]: block,
        });
    }, [mergedVisible, className, arrow, disabled, bordered]);

    return (
        <div style={style} className={mergedClass}>
            <DropdownContext.Provider value={contextValue}>
                {children}
            </DropdownContext.Provider>
        </div>
    );
};

Dropdown.Trigger = DropdownTrigger;
Dropdown.Overlay = DropdownOverlay;
Dropdown.Item = DropdownItem;

export default Dropdown;
