import WalletBase from 'Utils/wallet/base';
import * as PolkadotExtension from '@polkadot/extension-dapp';
import { InjectedAccountWithMeta, InjectedExtension } from '@polkadot/extension-inject/types';
import { ApiPromise, WsProvider, Keyring } from '@polkadot/api';
import { show as showTransaction } from 'Components/transaction';
import { AccountData, AccountInfo } from '@polkadot/types/interfaces';
import BalanceChangeEvent from 'Utils/events/balance-change';
import { Free } from 'Utils/entities/free';
import * as APIPromiseType from './polkadot.type.json';
import { wait } from 'Utils/index';
import { SubmittableExtrinsic } from '@polkadot/api/submittable/types';
import { formatBalance, BN, u8aToHex } from '@polkadot/util';
import { valueToBalance } from 'Utils/convert';
import { IPrice } from 'Components/input/price';
import localeCache from 'Utils/locale';

const WS_PROVIDER = 'wss://testnet-a-rpc.polkadomain.org';

export const keyring = new Keyring({ type: 'sr25519' });

export enum DomainRecordType {
    BTC = 0,
    ETH = 1,
    DOT = 2,
    KSM = 3,
}

export enum TokenType {
    NAME = 0,
    AUSD = 1,
    DOT = 2,
    LDOT = 3,
    RENBTC = 4,
    KAR = 128,
    KUSD = 129,
    KSM = 130,
    LKSM = 131,
}

formatBalance.setDefaults({
    decimals: 12,
});

// Pure wallet api wrapper, only do chain transactions, should been verified in upper business
export class PolkaDot extends WalletBase {
    static get injected() {
        return PolkadotExtension.isWeb3Injected;
    }

    private ready: Promise<void> | undefined;

    public apiReady: Promise<ApiPromise> | undefined;

    public extension: InjectedExtension | undefined;

    public accounts: InjectedAccountWithMeta[] = [];

    private accountAddress?: string;

    private readonly provider: WsProvider;

    public api: ApiPromise | undefined;

    private subscribers: Record<string, any> = {};

    public balance: AccountData | undefined;

    constructor() {
        super();

        this.provider = new WsProvider(WS_PROVIDER);
    }

    get injected() {
        return PolkaDot.injected;
    }

    public async enable() {
        if (!this.ready) {
            this.ready = this.inject();
        }

        return this.ready;
    }

    private async injectWithRetry(count = 0): Promise<InjectedExtension[]> {
        const allInjected = await PolkadotExtension.web3Enable('polka domain app');

        if (!allInjected.length && count <= 2) {
            await wait(1000);
            return this.injectWithRetry(count + 1);
        }

        return allInjected;
    }

    private async inject() {
        const allInjected = await this.injectWithRetry();

        this.extension = allInjected[0];

        if (!this.extension) {
            return;
        }

        this.accounts = await PolkadotExtension.web3Accounts();
        this.apiReady = ApiPromise.create({
            provider: this.provider,
            types: APIPromiseType,
        }).then((api) => {
            this.api = api;

            formatBalance.setDefaults({
                decimals: api.registry.chainDecimals,
            });
            return api;
        });
    }

    public get address(): string | undefined {
        return this.accountAddress;
    }

    public set address(address: string | undefined) {
        if (!address) {
            throw new Error(
                localeCache.formatMessage('error.account.notfound'),
            );
        }

        const exist = this.accounts.find(item => item.address === address);

        if (!exist) {
            throw new Error(
                localeCache.formatMessage('error.account.notfound'),
            );
        }

        this.unSubscribeAccount();

        this.accountAddress = address;

        this.subscribeAccount();
    }

    public get account() {
        const address = this.accountAddress;

        return this.accounts.find(item => item.address === address);
    }

    private unSubscribeAccount() {
        if (this.subscribers.account) {
            this.balance = undefined;
            this.subscribers.account();
            delete this.subscribers.account;
            this.dispatchEvent(new BalanceChangeEvent({ free: 0 as any } as any));
        }
    }

    private async subscribeAccount() {
        await this.apiReady;

        this.subscribers.account = await this.api?.query.system.account(
            this.accountAddress,
            ({ data }: AccountInfo) => {
                this.balance = data;

                this.dispatchEvent(new BalanceChangeEvent(data));
            },
        );
    }

    get free() {
        return new Free(this.balance?.free);
    }

    get decimal() {
        if (!this.api) {
            return 12;
        }
        return this.api?.registry.chainDecimals[0];
    }

    get SI() {
        return formatBalance.getOptions(this.decimal);
    }

    public async walletTransaction(input?: SubmittableExtrinsic<'promise'>) {
        if (!input) {
            throw new Error(
                localeCache.formatMessage('error.action.invalid'),
            );
        }

        await this.apiReady;
        if (!this.address) {
            throw new Error(localeCache.formatMessage('error.address.empty'));
        }

        return new Promise<void>(async (resolve, reject) => {
            let resolved = false;

            try {
                const unsubscribe = await input.signAndSend(this.address!, {
                    signer: this.extension!.signer,
                }, (res) => {
                    if (res.status.isFinalized) {
                        console.log('Success', res.status.asFinalized.toHex());
                    } else {
                        console.log('Status of transfer: ' + res.status.type);
                    }

                    res.events.forEach(({ phase, event: { data, method, section } }) => {
                        console.log(phase.toString() + ' : ' + section + '.' + method + ' ' + data.toString());
                    });

                    if (resolved) {
                        return;
                    }

                    if (res.isError) {
                        resolved = true;
                        reject(res.internalError);
                        unsubscribe && unsubscribe();
                        return;
                    }

                    if (res.isFinalized) {
                        resolved = true;
                        unsubscribe && unsubscribe();

                        if (res.dispatchError) {
                            console.log(res.dispatchError.toString());
                            if (res.dispatchError.isModule) {
                                const decoded = this.api!.registry.findMetaError(res.dispatchError.asModule);

                                const docs = decoded.docs || (decoded as any).documentation as typeof decoded.docs;

                                const hasMessage = docs.length;

                                const error = new Error(
                                    `${decoded.name}${hasMessage ?
                                        ':' :
                                        ''}${docs.join('')}`,
                                );

                                console.log('error', decoded);

                                reject(error);

                                return;
                            } else {
                                const str = res.dispatchError.toString();

                                console.log('error', str);

                                const error = new Error(
                                    localeCache.formatMessage('error.unknown', {
                                        error: str,
                                    }),
                                );

                                reject(error);
                                return;
                            }
                        }

                        resolve();
                        return;
                    }
                });
            } catch (e) {
                console.error(e);
                if (!resolved) {
                    reject(e);
                    resolved = true;
                }
            }
        });
    }

    public async registerDomain(domain: string) {
        await this.apiReady;

        const [loading, destroyLoading] = showTransaction({
            visible: true,
            loading: true,
            title: localeCache.formatMessage('wallet.domain.register.title'),
            description: localeCache.formatMessage('wallet.domain.register.description'),
            extra: localeCache.formatMessage('wallet.domain.register.extra'),
        });

        await new Promise<void>(async (resolve, reject) => {
            try {
                await this.walletTransaction(
                    this.api?.tx.domainRegistrar.register(
                        domain,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                    ),
                );

                loading.current?.set({
                    title: localeCache.formatMessage('wallet.domain.register.success.title'),
                    description: localeCache.formatMessage('wallet.domain.register.success.description'),
                    link: undefined,
                    extra: undefined,
                    loading: false,
                    finished: true,
                    onSuccess: () => {
                        destroyLoading();
                        resolve();
                    },
                });
            } catch (e) {
                destroyLoading();
                reject(e);
            }
        });
    }

    public async bindToDomain(domain: string, type: DomainRecordType, input: string = '') {
        await this.apiReady;

        const [loading, destroyLoading] = showTransaction({
            visible: true,
            loading: true,
            title: localeCache.formatMessage('wallet.domain.bind.title'),
            description: localeCache.formatMessage('wallet.domain.bind.description'),
            extra: localeCache.formatMessage('wallet.domain.bind.extra'),
        });

        const value = u8aToHex(keyring.decodeAddress(keyring.encodeAddress(input)));

        await new Promise<void>(async (resolve, reject) => {
            try {
                await this.walletTransaction(this.api?.tx.domainRegistrar.bindAddress(
                    domain,
                    type === DomainRecordType.BTC ? value : undefined,
                    type === DomainRecordType.ETH ? value : undefined,
                    type === DomainRecordType.DOT ? value : undefined,
                    type === DomainRecordType.KSM ? value : undefined,
                ));

                loading.current?.set({
                    title: localeCache.formatMessage('wallet.domain.bind.success.title'),
                    description: localeCache.formatMessage('wallet.domain.bind.success.description'),
                    link: undefined,
                    extra: undefined,
                    loading: false,
                    finished: true,
                    onSuccess: () => {
                        destroyLoading();
                        resolve();
                    },
                });
            } catch (e) {
                destroyLoading();
                reject(e);
            }
        });
    }

    public async transferDomain(domain: string, target: string) {
        await this.apiReady;

        const [loading, destroyLoading] = showTransaction({
            visible: false,
            loading: true,
            title: localeCache.formatMessage('wallet.domain.transfer.out.title'),
            description: localeCache.formatMessage('wallet.domain.transfer.out.description'),
            extra: localeCache.formatMessage('wallet.domain.transfer.out.extra'),
        });

        await new Promise<void>(async (resolve, reject) => {
            try {
                await this.walletTransaction(this.api?.tx.domainRegistrar.transfer(
                    target,
                    domain,
                ));

                loading.current?.set({
                    title: localeCache.formatMessage('wallet.domain.transfer.out.success.title'),
                    description: localeCache.formatMessage('wallet.domain.transfer.out.success.description'),
                    link: undefined,
                    extra: undefined,
                    loading: false,
                    finished: true,
                    onSuccess: () => {
                        destroyLoading();
                        resolve();
                    },
                });
            } catch (e) {
                destroyLoading();
                reject(e);
            }
        });
    }

    public async releaseDomain(domain: string) {
        await this.apiReady;

        const [loading, destroyLoading] = showTransaction({
            visible: false,
            loading: true,
            title: localeCache.formatMessage('wallet.domain.release.title'),
            description: localeCache.formatMessage('wallet.domain.release.description'),
            extra: localeCache.formatMessage('wallet.domain.release.extra'),
        });

        await new Promise<void>(async (resolve, reject) => {
            try {
                await this.walletTransaction(
                    this.api?.tx.domainRegistrar.deregister(
                        domain,
                    ),
                );

                loading.current?.set({
                    title: localeCache.formatMessage('wallet.domain.release.success.title'),
                    description: localeCache.formatMessage('wallet.domain.release.success.description'),
                    link: undefined,
                    extra: undefined,
                    loading: false,
                    finished: true,
                    onSuccess: () => {
                        destroyLoading();
                        resolve();
                    },
                });
            } catch (e) {
                destroyLoading();
                reject(e);
            }
        });
    }

    public async transactionToAddress(address: string, balance: number, unit: string) {
        await this.apiReady;

        const [loading, destroyLoading] = showTransaction({
            visible: false,
            loading: true,
            title: localeCache.formatMessage('wallet.transaction.title'),
            description: localeCache.formatMessage('wallet.transaction.description'),
        });

        const value = valueToBalance(balance, unit, this.decimal);

        await new Promise<void>(async (resolve, reject) => {
            try {
                await this.walletTransaction(
                    this.api?.tx.balances.transfer(
                        address,
                        value,
                    ),
                );

                loading.current?.set({
                    title: localeCache.formatMessage('wallet.transaction.title.success'),
                    description: localeCache.formatMessage('wallet.transaction.description.success'),
                    link: undefined,
                    extra: undefined,
                    loading: false,
                    finished: true,
                    onSuccess: () => {
                        destroyLoading();
                        resolve();
                    },
                });
            } catch (e) {
                destroyLoading();
                reject(e);
            }
        });
    }

    public async makeOrder(nft: [number, number], balance: number, unit: string) {
        await this.apiReady;

        const [loading, destroyLoading] = showTransaction({
            visible: false,
            loading: true,
            title: localeCache.formatMessage('wallet.order.fixed.title'),
            description: localeCache.formatMessage('wallet.order.fixed.description'),
            extra: localeCache.formatMessage('wallet.order.fixed.extra'),
        });

        const value = valueToBalance(balance, unit, this.decimal);

        await new Promise<void>(async (resolve, reject) => {
            try {
                await this.walletTransaction(
                    this.api?.tx.order.makeOrder(
                        nft,
                        TokenType.NAME,
                        value,
                    ),
                );

                loading.current?.set({
                    title: localeCache.formatMessage('wallet.order.fixed.success.title'),
                    description: localeCache.formatMessage('wallet.order.fixed.success.description'),
                    link: undefined,
                    extra: undefined,
                    loading: false,
                    finished: true,
                    onSuccess: () => {
                        destroyLoading();
                        resolve();
                    },
                });
            } catch (e) {
                destroyLoading();
                reject(e);
            }
        });
    }

    public async createAuction(nft: [number, number], minimal: IPrice, endTime: number) {
        await this.apiReady;

        const [loading, destroyLoading] = showTransaction({
            visible: false,
            loading: true,
            title: localeCache.formatMessage('wallet.order.auction.title'),
            description: localeCache.formatMessage('wallet.order.auction.description'),
            extra: localeCache.formatMessage('wallet.order.auction.extra'),
        });

        const value = valueToBalance(minimal.value, minimal.unit, this.decimal);

        const duration = Math.ceil((endTime - Date.now()) / 12000);

        await new Promise<void>(async (resolve, reject) => {
            try {
                await this.walletTransaction(
                    this.api?.tx.auction.createAuction(
                        nft,
                        TokenType.NAME,
                        value,
                        duration,
                    ),
                );

                loading.current?.set({
                    title: localeCache.formatMessage('wallet.order.auction.success.title'),
                    description: localeCache.formatMessage('wallet.order.auction.success.description'),
                    link: undefined,
                    extra: undefined,
                    loading: false,
                    finished: true,
                    onSuccess: () => {
                        destroyLoading();
                        resolve();
                    },
                });
            } catch (e) {
                destroyLoading();
                reject(e);
            }
        });
    }

    public async takeDomainOrder(orderId: string, price: string) {
        await this.apiReady;

        const [loading, destroyLoading] = showTransaction({
            visible: false,
            loading: true,
            title: localeCache.formatMessage('wallet.order.take.fixed.title'),
            description: localeCache.formatMessage('wallet.order.take.fixed.description'),
            extra: localeCache.formatMessage('wallet.order.take.fixed.extra'),
        });

        await new Promise<void>(async (resolve, reject) => {
            try {
                await this.walletTransaction(
                    this.api?.tx.order.takeOrder(
                        orderId,
                        new BN(price),
                    ),
                );

                loading.current?.set({
                    title: localeCache.formatMessage('wallet.order.take.fixed.success.title'),
                    description: localeCache.formatMessage('wallet.order.take.fixed.success.description'),
                    link: undefined,
                    extra: undefined,
                    loading: false,
                    finished: true,
                    onSuccess: () => {
                        destroyLoading();
                        resolve();
                    },
                });
            } catch (e) {
                destroyLoading();
                reject(e);
            }
        });
    }

    public async bidOrder(orderId: string, price: IPrice) {
        await this.apiReady;

        const [loading, destroyLoading] = showTransaction({
            visible: false,
            loading: true,
            title: localeCache.formatMessage('wallet.order.take.auction.title'),
            description: localeCache.formatMessage('wallet.order.take.auction.description'),
            extra: localeCache.formatMessage('wallet.order.take.auction.extra'),
        });

        const value = valueToBalance(price.value, price.unit, this.decimal);

        await new Promise<void>(async (resolve, reject) => {
            try {
                await this.walletTransaction(
                    this.api?.tx.auction.bidAuction(
                        orderId,
                        value,
                    ),
                );

                loading.current?.set({
                    title: localeCache.formatMessage('wallet.order.take.auction.success.title'),
                    description: localeCache.formatMessage('wallet.order.take.auction.success.description'),
                    link: undefined,
                    extra: undefined,
                    loading: false,
                    finished: true,
                    onSuccess: () => {
                        destroyLoading();
                        resolve();
                    },
                });
            } catch (e) {
                destroyLoading();
                reject(e);
            }
        });
    }

    // todo
    public async transferIn(_domain: string, _code: string) {

    }
}

const wallet = new PolkaDot();

export default wallet;
