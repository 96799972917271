import * as React from 'react';
import Style from './index.less';
import { ComponentBase } from 'Components/global';
import classNames from 'classnames';

interface IProps extends ComponentBase {

}

const Group: React.FC<IProps> = (props) => {
    const { children, className, style } = props;

    const innerClassName = React.useMemo(() => {
        return classNames(Style.layoutGroup, className);
    }, [className]);

    return (
        <div className={innerClassName} style={style}>
            {children}
        </div>
    );
};

export default Group;
