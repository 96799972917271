import * as React from 'react';
import Loading from 'Components/loading';
import FormattedMessage from 'Components/intl/message';

export type Fallback = NonNullable<React.ReactNode> | null;

interface IProps<T extends React.ComponentType<any> = React.ComponentType> {
    fallback?: Fallback;
    component?: () => Promise<{ default: T }>;
    children?: React.ReactElement;
}

const Lazy = (props: IProps & any) => {
    const { fallback, component, ...extra } = props;

    const mergedFallback = React.useMemo(() => {
        if(fallback){
            return fallback
        }

        return (
            <Loading block>
                <FormattedMessage id="common.loading" />
            </Loading>
        )
    }, [fallback])

    return (
        <React.Suspense fallback={mergedFallback}>
            {React.createElement(React.lazy((component) as () => Promise<{ default: React.ComponentType }>), extra)}
        </React.Suspense>
    );
};

export default Lazy;

export const lazy = (Component: () => Promise<{ default: any }>, fallback?: Fallback) => {
    return (props: any) => {
        return (
            <Lazy fallback={fallback} {...props} component={Component}/>
        );
    };
};

