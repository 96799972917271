import * as React from 'react';
import PrefixContext from 'Models/prefix';

const usePrefix = (prefix?: string) => {
    const context = React.useContext(PrefixContext);

    return React.useMemo(() => {
        return (prefix && prefix !== '') ? prefix : context;
    }, [prefix, context]);
};

export default usePrefix;
