import { dirname, basename } from 'path';
import { ILocalConfig, LANG } from 'Providers/local-provider';

const context = require.context('./', true, /[a-z]+(-[a-z]+)?\/index.(ts|js)x?$/i, 'lazy-once');

const Locals: Record<LANG, () => Promise<ILocalConfig>> = {} as any;

context.keys().forEach((k) => {
    const base: LANG = basename(dirname(k)) as any;

    Locals[base] = () => context(k) as any;
});

export default Locals;
