import { keyring } from 'Utils/wallet/wallets/polkadot';

export const fromHex = (hex: string) => {
    hex = hex ? hex.replace(/^0x/, '') : hex;

    if (!hex) {
        return undefined;
    }

    return keyring.encodeAddress(keyring.decodeAddress(hex));
};
