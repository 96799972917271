import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from 'Components/layout';
import LocalProvider from 'Providers/local-provider';
import AppRouter from './routes';
import NavigationProvider from 'Providers/navigation-provider';
import Lazy from 'Components/lazy';

const App = () => {
    return (
        <LocalProvider>
            <Lazy component={() => import('Providers/wallet-provider')}>
                <BrowserRouter>
                    <NavigationProvider />
                    <Layout>
                        <AppRouter />
                    </Layout>
                </BrowserRouter>
            </Lazy>
        </LocalProvider>
    );
};

export default App;
