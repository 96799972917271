import { ILocalItem } from 'Providers/local-provider';
import { MessageDescriptor, createIntl, IntlShape } from 'react-intl';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import { Options as IntlMessageFormatOptions } from 'intl-messageformat/src/core';

class LocaleCache {
    private innerLocal?: ILocalItem;

    private intl?: IntlShape;

    set locale(locale: ILocalItem | undefined) {
        if (!locale) {
            return;
        }
        this.innerLocal = locale;
        this.intl = createIntl({
            ...locale,
            messages: locale.Messages,
        });
    }

    get locale(): ILocalItem | undefined {
        return this.innerLocal;
    }

    get lang() {
        return this.intl?.locale || 'en-us';
    }

    get messages() {
        return this.intl?.messages || {};
    }

    formatMessage(
        message: string | MessageDescriptor,
        values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
        options?: IntlMessageFormatOptions,
    ) {
        if (!this.intl) {
            return '';
        }

        const descriptor = typeof message === 'string' ? {
            id: message,
            defaultMessage: this.messages[message],
        } : message;

        if (!descriptor) {
            return '';
        }

        return this.intl.formatMessage(descriptor, values, options);
    }
}

const localeCache = new LocaleCache();

export default localeCache;
