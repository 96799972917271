import { useContext } from 'react';
import WalletContext from 'Models/wallet';
import * as React from 'react';
import wallet from 'Utils/wallet/wallets/polkadot';
import BalanceChangeEvent from 'Utils/events/balance-change';
import { Free } from 'Utils/entities/free';
import { useMounted } from 'Hooks/use-mounted';

const useAccount = () => {
    const [balance, setBalance] = React.useState(new Free(wallet.balance?.free || 0));
    const [reserved, setReserved] = React.useState(new Free(wallet.balance?.reserved || 0));

    const mounted = useMounted();

    const { account, accounts, setAccount } = useContext(WalletContext);

    const handleBalanceChange = React.useCallback((e: BalanceChangeEvent) => {
        if (!mounted.current) {
            return;
        }

        setBalance(e.free);
        setReserved(e.reserved);
    }, [setBalance, mounted]);

    React.useEffect(() => {
        wallet.addEventListener('balance-change', handleBalanceChange as any);

        return () => {
            wallet.removeEventListener('balance-change', handleBalanceChange as any);
        };
    }, []);

    return {
        balance,
        reserved,
        address: account,
        account: accounts.find(item => item.address === account),
        accounts,
        setAccount,
    };
};

export default useAccount;
