import * as React from 'react';
import { Link } from 'react-router-dom';
import useAccount from 'Hooks/use-account';
import { Identicon } from '@polkadot/react-identicon';
import AccountSelector from 'Components/account-selector';
import PrefixProvider from 'Providers/prefix-provider';
import FormattedMessage from 'Components/intl/message';
import classNames from 'classnames';
import Style from './index.less';
import Dimmer from 'Components/dimmer';

interface IProps {
    current: string;
}

const Navigation = (props: IProps) => {
    const { current = '' } = props;

    const [accountSelectorVisible, setAccountSelectorVisible] = React.useState(false);
    const { account, address, balance } = useAccount();
    const [menuVisible, setMenuVisible] = React.useState(false);
    const [walletVisible, setWalletVisible] = React.useState(false);

    const handleSelectorChange = React.useCallback(() => {
        setAccountSelectorVisible(false);
    }, []);

    const handleSelectorShow = React.useCallback(() => {
        setAccountSelectorVisible(visible => !visible);
    }, []);

    const handleMenuTriggerClick = React.useCallback(() => {
        setMenuVisible(visible => !visible);
    }, []);

    const handleWalletTriggerClick = React.useCallback(() => {
        setWalletVisible(visible => !visible);

        setAccountSelectorVisible(false);
    }, []);

    const leftWrapperClassNames = React.useMemo(() => {
        return classNames(Style.navigationLeftWrapper, {
            visible: menuVisible,
        });
    }, [menuVisible]);

    const rightWrapperClassNames = React.useMemo(() => {
        return classNames(Style.navigationRightWrapper, {
            [Style.opened]: accountSelectorVisible,
            visible: walletVisible,
        });
    }, [walletVisible, accountSelectorVisible]);

    return (
        <PrefixProvider value="component.navigation">
            <nav className={Style.navigation}>
                <div className={Style.navContainer}>
                    <button
                        className={Style.navigationMenuTrigger}
                        onClick={handleMenuTriggerClick}
                    />
                    <img className={Style.navigationLogo} src={require('../../assets/logo.png').default} alt="logo" />
                    <ul className={leftWrapperClassNames}>
                        <li aria-selected={current === 'dashboard'}>
                            <Link to="/dashboard">
                                <FormattedMessage id="dashboard" />
                            </Link>
                        </li>
                        <li aria-selected={current === 'transaction'}>
                            <Link to="/transactions/send">
                                <FormattedMessage id="transaction.send" />
                            </Link>
                        </li>
                        <li aria-selected={current === 'domains'}>
                            <Link to="/domains">
                                <FormattedMessage id="domains" />
                            </Link>
                        </li>
                        <li aria-selected={current === 'domains/market'}>
                            <Link to="/domains/market">
                                <FormattedMessage id="domains.market" />
                            </Link>
                        </li>
                    </ul>

                    <button
                        className={Style.navigationWalletTrigger}
                        onClick={handleWalletTriggerClick}
                    />
                    <ul
                        className={rightWrapperClassNames} onClick={handleSelectorShow}
                    >
                        <span className={Style.amountWrapper}>
                            <span className={Style.balance}>{balance.number}</span>
                            <span className={Style.unit}>{balance.unit}</span>
                        </span>
                        <span className={Style.name}>{account?.meta.name}</span>
                        <Identicon
                            size={32}
                            className={Style.avatar}
                            value={address}
                            theme="polkadot"
                        />
                    </ul>
                </div>
                <AccountSelector visible={accountSelectorVisible} onChange={handleSelectorChange} />
            </nav>
            {menuVisible ? <Dimmer onClick={handleMenuTriggerClick} /> : null}
            {walletVisible ? <Dimmer onClick={handleWalletTriggerClick} /> : null}
        </PrefixProvider>
    );
};

export default Navigation;
