import * as React from 'react';
import { PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import StatementContext from 'Models/statement';

interface IProps {
    condition: boolean | (() => boolean);
}

const IF = (props: PropsWithChildren<IProps>) => {
    const { condition, children } = props;

    const context = useContext(StatementContext);

    const result = useMemo(() => {
        return typeof condition === 'function' ? condition() : condition;
    }, [condition]);

    useEffect(() => {
        context.change && context.change(result);
    }, [result, context]);

    if (result) {
        return (
            <>
                {children}
            </>
        );
    }

    return null;
};

export default IF;
