import * as React from 'react';

interface IStatementContext {
    state?: boolean;
    change?: (value: boolean) => void;
}

const StatementContext = React.createContext<IStatementContext>({});

export default StatementContext;
