import * as React from 'react';
import Modal from 'Components/modal';
import PreventUnload from 'Components/prevent-unload';
import { render, unmountComponentAtNode } from 'react-dom';
import ChainLoading from 'Components/loading/chain';
import classNames from 'classnames';
import Button from 'Components/button';
import Style from './index.less';
import localeCache from 'Utils/locale';
import { IntlProvider } from 'react-intl';

interface IProps {
    title?: string;
    description?: string;
    visible?: boolean;
    loading?: boolean;
    link?: string;
    extra?: string;
    finished?: boolean;
    successAction?: string;
    onSuccess?: () => void;
}

interface IRefProps extends IProps {
    show: () => void;
    hide: () => void;
    set: (value: Partial<IProps>) => void;
}

const Transaction = React.forwardRef<IRefProps, IProps>((props, ref) => {
    const [state, setState] = React.useState<IProps>(() => {
        const { visible = true, loading = false, ...extra } = props;
        return {
            ...extra,
            visible,
            loading,
        };
    });

    React.useEffect(() => {
        const { visible = true, loading = false, ...extra } = props;
        setState({
            ...extra,
            visible,
            loading,
        });
    }, [props, setState]);

    React.useImperativeHandle(ref, () => {
        return {
            ...state,
            show() {
                setState(prev => {
                    return {
                        ...prev,
                        visible: true,
                    };
                });
            },
            hide() {
                setState(prev => {
                    return {
                        ...prev,
                        visible: false,
                    };
                });
            },
            set(state: Partial<IProps>) {
                setState(prev => {
                    return {
                        ...prev,
                        ...state,
                    };
                });
            },
        };
    }, [{}]);

    const mergedClass = React.useMemo(() => {
        return classNames(Style.transactionProcess, {
            [Style.transactionProcessFinished]: state.finished,
        });
    }, [state.finished]);

    const handleClick = () => {
        state.onSuccess && state.onSuccess();
    };

    return (
        <PreventUnload>
            <Modal visible={true} className={mergedClass}>
                {state.title ? (<h1 className={Style.transactionProcessTitle}>{state.title}</h1>) : null}
                {
                    state.description ?
                        (
                            <p
                                className={Style.transactionProcessDescription}
                                dangerouslySetInnerHTML={{ __html: state.description }}
                            />
                        ) : null
                }
                {
                    state.link ?
                        (
                            <a
                                href={state.link}
                                target="_blank"
                                rel="noreferrer noopener"
                                className={Style.transactionProcessLink}
                            >
                                Learn More
                            </a>
                        ) : null
                }
                {state.loading ? <ChainLoading className={Style.transactionProcessLoading} /> : null}
                {
                    state.extra ? (
                        <p
                            className={Style.transactionProcessExtra}
                            dangerouslySetInnerHTML={{ __html: state.extra }}
                        />
                    ) : null
                }
                {
                    state.finished ? (
                        <Button type="primary" block onClick={handleClick} className={Style.transactionProcessAction}>
                            {
                                state.successAction || localeCache.formatMessage('common.action.close')
                            }
                        </Button>
                    ) : null
                }
            </Modal>
        </PreventUnload>
    );
});

export const show = (props: IProps = {}): [React.RefObject<IRefProps>, () => void] => {
    const container = window.document.createElement('div');
    window.document.body.appendChild(container);

    const ref = React.createRef<IRefProps>();

    let rendered = false;

    const destroy = () => {
        if (rendered) {
            return;
        }
        rendered = false;
        unmountComponentAtNode(container);
        window.document.body.removeChild(container);
    };

    render(
        <IntlProvider locale={localeCache.lang} messages={localeCache.messages}>
            <Transaction ref={ref} {...props} />
        </IntlProvider>,
        container,
    );

    return [ref, destroy];
};

export default Transaction;
