import * as React from 'react';

interface IProps {
    prevent?: boolean;
}

const PreventUnload: React.FC<IProps> = (props) => {
    const { prevent = true, children } = props;

    React.useEffect(() => {
        const handler = (e: BeforeUnloadEvent) => {
            e.preventDefault();
            e.stopPropagation();
            e.returnValue = '';

            return '';
        };

        window.addEventListener('beforeunload', handler);

        return () => {
            window.removeEventListener('beforeunload', handler);
        };
    }, [prevent]);

    return (
        <>{children}</>
    );
};

export default PreventUnload;
